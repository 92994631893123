import React from "react"
import styles from './index.module.css'

import MainLayout from "../layouts/MainLayout"
import SEO from "../components/seo"
import SignupButton from "../components/SignupButton"
import Chrome from '../components/Chrome'


// images
import example from '../images/example.png'
import analytics from '../images/analytics.png'
import theme from '../images/theme.png'
import integrations from '../images/integrations.png'

const IndexPage = () => {
  return (
    <MainLayout>
      <SEO title="Crowdlever - Recent order notifications for your online store" />

      <div className={styles.heroBackground}>
        <div className={styles.hero}>
          <div className={styles.left}>
            <div className={styles.title}>Use social proof to drive more sales</div>
            <div className={styles.explainer}>
              Show recent order notifications to increase your social proof, and drive more sales.
            </div>
            <div className={styles.signup}>
              <SignupButton href={`${process.env.GATSBY_FRONTEND_APP_URL}/signup`} />
              <div className={styles.beta}>Free 30 day trial, no credit card required</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.integrations}>
        <div className={styles.section}>
          <div className={styles.title}>Supported platforms</div>
          <div className={styles.image}>
            <img src={integrations} />
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.box}>
          <div className={styles.title}>How it works</div>
          <div className={styles.description}>
            <p>Our software plugs into your e-commerce site and automatically starts displaying recent purchases to your prospective customers</p>
          </div>
        </div>
        <div className={styles.image}>
          <Chrome>
            <img src={example} />
          </Chrome>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.box}>
          <div className={styles.title}>Analytics: track how your notifications are performing</div>
          <div className={styles.description}>
            <p>See total views, clicks and the click through rate for your notifications.</p>
          </div>
        </div>
        <div className={styles.image}>
          <Chrome>
            <img src={analytics} />
          </Chrome>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.box}>
          <div className={styles.title}>Theming &amp; customisation: your voice, your brand</div>
          <div className={styles.description}>
            <p>With Crowdlever, you are in total control of how your recent order notifications appear. Customise to your heart's delight.</p>
          </div>
        </div>
        <div className={styles.image}>
          <Chrome>
            <img src={theme} />
          </Chrome>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.signup}>
          <SignupButton href={`${process.env.GATSBY_FRONTEND_APP_URL}/signup`} />
          <div className={styles.beta}>Free 30 day trial, no credit card required</div>
        </div>
      </div>


      
    </MainLayout>
  )
}

export default IndexPage