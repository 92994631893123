import React from 'react'
import styles from './Chrome.module.css'

const Chrome = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <div className={styles.button}></div>
          <div className={styles.button}></div>
          <div className={styles.button}></div>
        </div>
      </div>
      <div className={styles.body}>
        {children}
      </div>
    </div>
  )
}

export default Chrome